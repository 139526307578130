import axios from "axios";
import { baseUrl, headers } from "../config/env";


// no Used
export function fetchAccount() {
  return axios.get(baseUrl + "/api/v1/account/teixon", { headers });
}
//okok
export function fetchAccountList() {
  return axios.get(baseUrl + "/api/v1/account/list");
}